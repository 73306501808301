<!--
 * @Description: 职位
 * @Author: 琢磨先生
 * @Date: 2022-05-22 15:08:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-13 11:23:37
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit" round icon="plus" v-if="is_can_edit"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data job-container">
    <el-row :gutter="20">
      <el-col :span="5" style="height: 100%;">
        <div class="dept-box">
          <el-scrollbar>
            <el-tree
              ref="tree"
              :data="deptList"
              node-key="id"
              :expand-on-click-node="false"
              highlight-current
              :props="{ children: 'children', label: 'name' }"
              default-expand-all
              @current-change="treeNodeChange"
            />
          </el-scrollbar>
        </div>
      </el-col>
      <el-col :span="19" v-loading="loading">
        <el-table :data="tableData" border>
          <el-table-column prop="id" label="编号" width="80"> </el-table-column>

          <el-table-column prop="name" label="名称" width="180">
          </el-table-column>
          <el-table-column prop="dept_name" label="部门"> </el-table-column>
          <el-table-column prop="create_at" label="创建时间" width="180">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template #default="scope">
              <el-button type="primary" link size="small" @click="onEdit(scope.row)" v-if="is_can_edit"
                >修改</el-button
              >
              <el-popconfirm
                title="确定要删除？"
                @confirm="onDelete(scope.row)" v-if="is_can_del"
              >
                <template #reference>
                  <el-button type="danger" link size="small" 
                    >删除</el-button
                  >
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.counts > 0"
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="query.pageIndex"
          :page-sizes="[20, 40, 60, 80, 100]"
          :page-size="query.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="tableData.counts"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </el-card>
  <edit-job :item="current" :depts="originalDept" :reload="loadData"></edit-job>
</template>

<script>
import dept_api from "../../../http/dept_api";
import EditJob from "./edit_job.vue";
export default {
  components: {
    EditJob,
  },
  data() {
    return {
      loading: false,
      current: null,
      originalDept: [],
      deptList: [],
      tableData: [],
      deptId: "",
      is_can_edit:false,
      is_can_del:false,
    };
  },
  created() {
    this.is_can_edit = this.$power('seller/v1/job/edit');
    this.is_can_del = this.$power('seller/v1/job/del');

    this.loadDepts();
    this.loadData();
  },
  methods: {
    /**
     * 加载部门
     */
    loadDepts() {
      dept_api.get_dept().then((res) => {
        if (res.code == 0) {
          this.originalDept = res.data;
          this.deptList = [
            {
              id: "",
              name: "全部",
            },
          ].concat(res.data);
          //tree默认选中第一个
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey("");
          });
        }
      });
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.get("seller/v1/job?deptId=" + this.deptId).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.loading = true;
      this.$http.get("seller/v1/job/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        } else {
          this.loading = false;
        }
      });
    },
    /**
     * 部门选择
     * tree节点点击事件
     */
    treeNodeChange(item) {
      this.deptId = item.id;
      this.loadData();
    },
  },
};
</script>

<style  scoped>
.job-container {
  /* min-height: 800px; */
}
.dept-box {
  height: 100%;
  border-radius: var(--el-card-border-radius);
  border: 1px solid var(--el-card-border-color);
  background-color: var(--el-card-bg-color);
  overflow: hidden;
  color: var(--el-text-color-primary);
  transition: var(--el-transition-duration);
}

.dept-box .el-card__body {
  height: 100% !important;
}

.job-container .el-row{
  height: 100%;
}

.job-container .el-row .el-col{
  display: flex;
  flex-direction: column;
}

</style>

<!--
 * @Description: 职位
 * @Author: 琢磨先生
 * @Date: 2022-05-22 15:08:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-25 16:22:48
-->

<template>
  <el-dialog
    v-model="visible"
    :title="title"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="部门" prop="deptIds">
        <el-cascader
          clearable
          :options="depts"
          v-model="form.deptIds"
          :props="{ label: 'name', value: 'id', checkStrictly: true }"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input
        ></el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button
        type="primary"
        @click="onSubmit"
        :loading="saving"
        :disabled="saving"
        >确定</el-button
      >
      <el-button @click="cancelClick" :disabled="saving">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      saving: false,
      visible: false,
      title: "",
      powers: [],
      form: {},
      deptList: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        deptIds: [
          {
            required: true,
            message: "部门必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload", "depts"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = {};
          this.visible = true;
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
            this.title = "修改职位";
          } else {
            this.title = "新增职位";
          }
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.dept_id = this.form.deptIds[this.form.deptIds.length - 1];
          console.log(this.form);
          this.saving = true;
          this.$http
            .post("seller/v1/job/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.visible = false;
      if (this.reload) {
        this.reload();
      }
    },
  },
};
</script>

<style   scoped>
</style>